import React, { useState, useEffect } from "react";
import Container from "screens/container";
import SampleTemplate from "./template";
import Helper from "shared/helper";
import { UpdateSession, GetStoredColumnsFromSession } from "shared/common";

const screenDescription = "Tiles Layout to display Buses in rows. One can perform different operations on Buses";

const Component = (props) => {
    const { screens } = props;
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState({});
    const [clearState, setClearState] = React.useState(false);
    const [loadEntity, setLoadEntity] = React.useState(false);
    const [state, setState] = React.useState(false);
    const { screenId, screenIndex } = Helper.GetQueryParams();

    const OnEntitySelected = async (e) => {
        const _nonCollections = e?.filter(x => !x.IsCollection && !x.IsNavigate) || [];
        setSelectedProperties(_nonCollections);
    }

    const OnMenuSelected = (e) => {
        let lastColumns = selectedColumns || {};
        const { options, value, name, seqNumber } = e;
        if (Helper.IsNullValue(value) || value === 'NONE') {
            delete lastColumns[name];
        } else {
            let tValue = value;
            /* if (name === 'Field_1') {
                tValue = options.find(x => x.Name === tValue).DisplayName;
            } */
            let _field = options.find(x => x.DisplayName === tValue);
            _field = { ..._field, seqNumber };
            lastColumns[name] = _field;
        }
        setSelectedColumns(lastColumns);
        setState(!state);
    }

    const LoadDetails = async () => {
        Reset();
        if (selectedProperties !== null && selectedProperties.length > 0) {
            let sessionColumns = await GetStoredColumnsFromSession(
                { FrontendScreenTemplateScreen: screenId, screenIndex }
            );
            setSelectedColumns(sessionColumns);
        }
    }

    const OnSaveClicked = async (e) => {
        if (!Helper.IsNullValue(selectedColumns) && !Helper.IsJSONEmpty(selectedColumns)) {
            const _screen = screens.find(x => parseInt(x.TeScreenId) === parseInt(screenId) && parseInt(x.index) === parseInt(screenIndex));
            let data = {
                ParentEntityType: Helper.FindFirst(selectedColumns)['EntityType'],
                EntityTypeName: Helper.FindFirst(selectedColumns)['EntityType'],
                FrontendScreenTemplateScreen: screenId,
                ScreenDescription: screenDescription,
                ScreenLayout: _screen.ScreenLayout,
                properties: selectedColumns,
                screenIndex
            };
            UpdateSession(data);
            Reset(true);
        }
    }

    const Reset = (all) => {
        setSelectedColumns({});
        if (all) {
            setClearState(!clearState);
            setSelectedProperties([]);
        }
    }

    if (loadEntity) {
        setLoadEntity(false);
        LoadDetails();
    }

    useEffect(() => { setLoadEntity(true); }, [selectedProperties]);

    return (
        <>
            <Container {...props} clear={clearState} onEntitySelected={OnEntitySelected} onSaveClicked={OnSaveClicked}
                enableSave={!Helper.IsJSONEmpty(selectedColumns)} folderLevel="../">
                <SampleTemplate selectedProperties={selectedProperties} selectedColumns={selectedColumns} onMenuSelected={OnMenuSelected} />
            </Container>
        </>
    );

};

export default Component;