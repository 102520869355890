import * as React from 'react';
import { IconButton, AppBar, Toolbar, CssBaseline, Stack, Box, useTheme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon, Settings as SettingsIcon } from '@mui/icons-material';
import LogoIcon from "assets/logo.png";
import Image from "../image";
import InputTitle from "../titleinput";
import CustomEditIcon from "../eicon";
import TimerSession from 'shared/useTimerSession';
import Session from 'shared/session';
import NavigationLogo from 'screens/popup/logoselection';
import { GetDocumentsApi } from 'shared/services';
import CustomDialog from 'components/dialog';
import Helper from "shared/helper";

const Component = ({ open, onDrawerClicked }) => {
    const [showSettings, setShowSettings] = React.useState(false);
    const [frontendAppLogo] = TimerSession("FrontendAppLogo");
    const [companyTitle] = TimerSession("CompanyName", null, "XYZ Company");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [logo, setLogo] = React.useState(LogoIcon);
    const [initDocument, setInitDocument] = React.useState(false);
    const [lastTheme] = TimerSession("theme");

    const theme = useTheme();

    const [backupTheme] = React.useState(lastTheme);

    const handleChangeTheme = (e, value) => {
        Session.Store('theme', value);
    };

    const onSettingsClicked = () => {
        setShowSettings(true);
    }

    const OnCloseClicked = (e) => {
        if (!e) {
            Session.Store('theme', backupTheme);
        }
        setShowSettings(false);
    }

    const OnInputChanged = (e) => {
        Session.Store("CompanyName", e);
    }

    const OnLogoClicked = () => {
        setOpenDialog(true);
    }

    const OnDialogClosed = (e) => {
        if (e) setLogo(e);
        setOpenDialog(false);
    }

    const GetLogoIcon = async () => {
        await GetDocumentsApi(frontendAppLogo)
            .then(async (doc) => {
                let data = null;
                if (!Helper.IsNullValue(doc)) {
                    if (doc.startsWith("data:")) {
                        data = doc.substring(doc.indexOf('data:'));
                    } else {
                        let tmp = doc.split('\r\n');
                        for (let img of tmp) {
                            if (img.startsWith("data:")) data = img;
                        }
                    }
                }
                setLogo(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    if (initDocument) {
        setInitDocument(false);
        GetLogoIcon();
    }


    React.useEffect(() => {
        if (frontendAppLogo) {
            setInitDocument(true);
        }
    }, [frontendAppLogo]);

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => onDrawerClicked()}>
                        {!open ? <MenuIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    <Box sx={{ display: "flex", border: theme.borderColor, p: 0, m: 0, mr: "5px", width: "40px", position: "relative" }}>
                        <Image sx={{ width: 40, height: 40, mr: 2, display: "blcok" }} alt="logo" src={logo} />
                        <CustomEditIcon sx={{ position: "absolute", bottom: 0, right: 0, p: 0 }} onClick={OnLogoClicked} />
                    </Box>
                    <InputTitle value={companyTitle} color={"#ffffff"} sx={{ color: "#ffffff", flexGrow: 1 }} onTitleChanged={OnInputChanged} />
                    <Stack direction={"row"} sx={{ alignItems: "center", flexGrow: 1, flexWrap: "nowrap", justifyContent: "flex-end" }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => onSettingsClicked()}>
                            <SettingsIcon />
                        </IconButton>
                        {/* <Typography variant="avatar" noWrap component="div" sx={{ marginRight: 1 }}>Welcome! User</Typography> */}
                        {/* <Avatar
                            style={{ cursor: "pointer" }}
                        ><PersonIcon />
                        </Avatar> */}
                    </Stack>
                </Toolbar>
            </AppBar>
            <CustomDialog open={showSettings} title={"Theme"} action={'apply'} onCloseClicked={OnCloseClicked}>

                <ToggleButtonGroup
                    color="primary"
                    value={lastTheme}
                    exclusive
                    onChange={handleChangeTheme}
                    aria-label="Platform"
                >
                    <ToggleButton value="Default">Default</ToggleButton>
                    <ToggleButton value="Light">Light</ToggleButton>
                    <ToggleButton value="Dark">Dark</ToggleButton>
                    <ToggleButton value="Red">Red</ToggleButton>
                    <ToggleButton value="Blue">Blue</ToggleButton>
                </ToggleButtonGroup>
            </CustomDialog>
            {openDialog && (
                <NavigationLogo onDialogClosed={OnDialogClosed} />
            )}
        </>
    );
}

export default Component;
