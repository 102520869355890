import React from "react";
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import Container from "screens/container";

const Component = (props) => {
    return (
        <>
            <Container {...props} landingPage={true} >
                <Typography variant="header" component="div" noWrap sx={{ flexGrow: 1 }}>
                    Follow below steps to complete the configuration
                </Typography>
                <List dense={true}>
                    <ListItem>
                        <ListItemText primary="1. Upload logo from left top corner (Optional step)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Give your own application name, next to logo (Optional step)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. Configure each screen from right hand side (Required step)" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Configure menu items (Recommended step)" />
                    </ListItem>
                </List>
            </Container>
        </>
    );

};

export default Component;
