import React from "react";
import { Typography, IconButton, Menu, MenuItem, Grid, useTheme, Box, TableContainer, Table, TableCell, TableBody, TableRow } from '@mui/material';
import { Card, CardActions, CardContent, CardMedia } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Edit as EditIcon, DeleteOutlined as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

const Samples = [
    {
        Field_0: 1,
        Field_1: require("assets/images/icons/laptop.png"),
        Field_2: "Laptop",
        Field_3: "Computers",
        Field_4: "HP Pavilion 14, Intel Core i5-1235U",
        Field_5: 25000,
        Field_6: "Gram",
        Field_7: "Product 1 Manu",
        Field_8: "#cccccc",
        Field_9: 51,
        Field_10: 5000,
        editable: false
    },
    {
        Field_0: 2,
        Field_2: "Bata",
        Field_3: "Appraisal",
        Field_4: "Mens Boss-Slick Formal Shoes",
        Field_5: 2500,
        Field_6: "Gram",
        Field_7: "Product 1 Manu",
        Field_8: "#cccccc",
        Field_9: 10,
        Field_10: 500,
        editable: false
    },
    {
        Field_0: 3,
        Field_2: "Fashion",
        Field_3: "Garments",
        Field_4: "FUNDAY FASHION Cotton Half Sleeve Printed Oversized T-Shirt",
        Field_5: 1500,
        Field_6: "Gram",
        Field_7: "Product 1 Manu",
        Field_8: "#cccccc",
        Field_9: 5,
        Field_10: 100,
        editable: false
    },
    {
        Field_0: 4,
        Field_1: require("assets/images/icons/camera.png"),
        Field_2: "HP Security Camera",
        Field_3: "Security",
        Field_4: "Wipro Smart Wireless Security Camera",
        Field_5: 4500,
        Field_6: "Gram",
        Field_7: "Product 1 Manu",
        Field_8: "#cccccc",
        Field_9: 12,
        Field_10: 400,
        editable: false
    },
    {
        Field_0: 5,
        Field_2: "Echo Dot (5th Gen)",
        Field_3: "Automation",
        Field_4: "Echo Dot (5th Gen) | Smart speaker with Bigger sound, Motion Detection, Temperature Sensor, Alexa and Bluetooth",
        Field_5: 5500,
        Field_6: "Gram",
        Field_7: "Product 1 Manu",
        Field_8: "#cccccc",
        Field_9: 17,
        Field_10: 550,
        editable: false
    }
];

const MenuItemList = (props) => {
    const { anchorEl, fieldId, onMenuClosed, selectedProperties, selectedColumns, seqNumber } = props;
    const open = Boolean(anchorEl);
    const [menuItems, setMenuItems] = React.useState([]);
    const theme = useTheme();

    const handleClose = (e, index) => {
        e.preventDefault();
        onMenuClosed({ name: fieldId, value: menuItems[index], seqNumber });
    };

    const IsMenuSelected = (e, v) => {
        if (selectedColumns && selectedColumns[e] && selectedColumns[e].DisplayName === v) return true;
        return false;
    }

    React.useEffect(() => {
        let mnuItems = [];
        let selectProps = selectedProperties;
        let lastSelected = Object.values(selectedColumns).map(x => x.DisplayName);

        selectProps = selectProps.filter(x => lastSelected.indexOf(x.DisplayName) === -1);

        if (fieldId === "Field_1") {
            let tmpList = selectProps.filter(z => z.HasStream).map(x => x.DisplayName) || [];
            let tmp = tmpList.length > 0 ? tmpList[0] : "";
            mnuItems = [tmp];
            /* if (!Helper.IsNullValue(tmp)) {
                tmp = tmp.split("/")[0];
                mnuItems = [tmp];
            } */
        } else {
            mnuItems = selectProps.filter(z => !z.HasStream).map(x => x.DisplayName);
        }
        setMenuItems(mnuItems);
    }, [selectedProperties, fieldId, selectedColumns]);

    if (!selectedProperties || selectedProperties.length === 0) {
        onMenuClosed();
        return;
    }

    return (
        <Menu
            id="basic-menu"
            open={open}
            onClose={() => onMenuClosed()}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            value={"InvoiceShipment/ShipmentTrackingNumber"}
            anchorEl={anchorEl}
        >
            {menuItems && menuItems.map((x, index) => (
                <MenuItem key={index} value={x} onClick={(e) => handleClose(e, index)} sx={{ backgroundColor: IsMenuSelected(fieldId, x) ? "rgba(25, 118, 210, 0.08)" : "inherit" }}>
                    {x}
                </MenuItem>
            ))}
            <MenuItem value={"NONE"} onClick={(e) => handleClose(e, -1)} sx={{ color: theme.palette.error.main }}>
                Remove Field
            </MenuItem>
        </Menu>
    );
}

const RenderMenuItem = (e) => {

    const { index, id, value, variant, onClick, count, color, component, label, sx } = e;

    return (
        <Typography gutterBottom id={id} name={id} variant={variant} component={component} color={color} sx={{
            display: 'block', cursor: count > 0 && index === 0 ? "pointer" : "default",
            '&:hover': {
                border: count > 0 && index === 0 && "1px dashed blue"
            },
            marginBottom: "2px",
            ...sx
        }} onClick={(e) => count > 0 && index === 0 ? onClick(e, true) : onClick(e, false)}>
            {label && <strong>{label}&nbsp;</strong>}{value}&nbsp;{count > 0 && index === 0 && <ArrowDropDown sx={{ verticalAlign: "middle" }} />}
        </Typography>
    )
}

const RenderCardMedia = ({ onMenuClicked, keyIndex, count, src, seqNumber }) => {


    const OnMenuClicked = (e, index, count, fieldId) => {
        e.preventDefault();
        if (onMenuClicked) onMenuClicked(e, index, count, fieldId, seqNumber);
    }

    if (src && src.endsWith(".png")) {

        return (
            <>
                {count > 0 && keyIndex === 0 &&
                    <Typography gutterBottom variant="body2" component="p" sx={{
                        margin: "10px",
                        display: 'block', cursor: count > 0 && keyIndex === 0 ? "pointer" : "default",
                        '&:hover': {
                            border: count > 0 && keyIndex === 0 && "1px dashed blue"
                        }
                    }}
                        onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_1")}
                    >Select Image&nbsp;<ArrowDropDown sx={{ verticalAlign: "middle" }} /></Typography>
                }
                <CardMedia
                    component="img"
                    sx={{ height: 140, objectFit: "contain" }}
                    src={src}
                />
            </>
        );
    }

    return (
        <>
            <Typography gutterBottom variant="body2" component="p" sx={{ margin: "10px" }}
                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_1")}
            >{src.split("/")[0]}&nbsp;<ArrowDropDown sx={{ verticalAlign: "middle" }} /></Typography>
            <CardMedia sx={{
                height: 140,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#8c92ac",
                textAlign: "center"
            }}>
                <ImageNotSupportedIcon />
            </CardMedia>
        </>
    );

}

const GridContainer = ({ children }) => {
    return (
        <>
            <Grid container sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                padding: 1
            }}>
                {children}
            </Grid>
        </>
    )
}

const CardItem = (props) => {

    const { keyIndex, count, width, row, onMenuClosed, selectedColumns } = props;

    const [fieldId, setFieldId] = React.useState(null);
    const [seqNumber, setSeqNumber] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const OnMenuClicked = (e, index, count, fieldId, seqNumber) => {
        e.preventDefault();
        if (count > 0 && index === 0) {
            setFieldId(fieldId);
            setSeqNumber(seqNumber);
            setAnchorEl(e.target);
        }
    }

    const OnMenuClosed = (e) => {
        setAnchorEl(null);
        setFieldId(null);
        setSeqNumber(null);
        if (onMenuClosed) onMenuClosed(e);
    }

    return (
        <>
            <Grid item>
                <Card sx={{
                    boxShadow: "none !important",
                    width: width || 345,
                    height: '100%',
                    display: "flex",
                    flexDirection: "column",
                    border: '0.75px solid #D2D4D7',
                    borderRadius: '15px',

                }}>
                    {row.Field_1 ? (
                        <RenderCardMedia seqNumber={1} src={row.Field_1} keyIndex={keyIndex} onMenuClicked={OnMenuClicked} count={count} />
                    ) : (
                        <CardMedia sx={{
                            height: 140,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#8c92ac",
                            textAlign: "center"
                        }} onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_1", 1)}>
                            <ImageNotSupportedIcon />
                        </CardMedia>
                    )}
                    <CardContent sx={{ flexGrow: 1 }}>
                        <RenderMenuItem id="Field_2" variant="h5" component="div"
                            sx={{
                                margin: 0,
                                fontWeight: 400,
                                fontSize: "1.5rem",
                                lineHeight: 1.334,
                                marginBottom: "0.35em !important"
                            }}
                            index={keyIndex} value={row.Field_2} count={count}
                            onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_2", 2)} />
                        <RenderMenuItem id="Field_4" variant="body2" component="p" color="text.secondary" index={keyIndex} value={row.Field_4} count={count}
                            onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_4", 3)} />
                        <Grid container direction="column">
                            <RenderMenuItem id="Field_3" variant="caption" label={"Type:"} color="text.secondary" index={keyIndex} value={row.Field_3} count={count}
                                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_3", 4)} />
                            <RenderMenuItem id="Field_5" variant="caption" label={"Price:"} color="text.secondary" index={keyIndex} value={row.Field_5} count={count}
                                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_5", 5)} />
                            <RenderMenuItem id="Field_8" variant="caption" label={"Color:"} color="text.secondary" index={keyIndex} value={row.Field_8} count={count}
                                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_8", 8)} />
                            <RenderMenuItem id="Field_6" variant="caption" label={"Unit Of Measurement:"} color="text.secondary" index={keyIndex} value={row.Field_6} count={count}
                                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_6", 6)} />
                            <RenderMenuItem id="Field_7" variant="caption" label={"Manufacturer:"} color="text.secondary" index={keyIndex} value={row.Field_7} count={count}
                                onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_7", 7)} />
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing sx={{ m: 0, p: 0 }}>
                        <TableContainer sx={{ width: "100%", m: 0, p: 0 }}>
                            <Box>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="view"
                                >
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="Edit"
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="Delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            <Table sx={{ display: 'table', width: '100%', border: 0 }}>
                                <TableBody>
                                    <TableRow sx={{ borderWidth: "1px 0px 0px 0px", borderStyle: "solid", borderColor: "#D2D4D7" }}>
                                        <TableCell sx={{ borderWidth: "0px 1px 0px 0px", borderStyle: "solid", padding: "8px", borderColor: "#D2D4D7" }}>
                                            <Typography gutterBottom component="p" sx={{ fontWeight: "bold" }}>Features</Typography>
                                            <Box sx={{ paddingTop: "2px", paddingLeft: "8px" }}>
                                                <RenderMenuItem id="Field_9" variant="caption" color="text.secondary" index={keyIndex} value={row.Field_9} count={count}
                                                    onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_9", 9)} />
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ padding: "8px", border: 0 }}>
                                            <Typography gutterBottom component="p" sx={{ fontWeight: "bold" }}>Discount</Typography>
                                            <Box sx={{ paddingTop: "2px", paddingLeft: "8px" }}>
                                                <RenderMenuItem id="Field_10" variant="caption" color="text.secondary" index={keyIndex} value={row.Field_10} count={count}
                                                    onClick={(e) => OnMenuClicked(e, keyIndex, count, "Field_10", 10)} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardActions>
                </Card>
            </Grid>
            {anchorEl && <MenuItemList selectedColumns={selectedColumns} seqNumber={seqNumber} fieldId={fieldId} anchorEl={anchorEl} {...props} onMenuClosed={OnMenuClosed} />}
        </>
    )
}

const Component = (props) => {

    const { selectedProperties, selectedColumns, onMenuSelected } = props;
    const [rows, setRows] = React.useState([]);

    const count = selectedProperties ? selectedProperties.length : 0;

    const OnMenuClosed = (e) => {
        if (e) {
            const { name, value, seqNumber } = e;
            if (onMenuSelected) onMenuSelected({ name, value, options: selectedProperties, seqNumber });

            let tmpOrg = rows;
            if (value) {
                tmpOrg[0][name] = value;
            } else {
                tmpOrg[0][name] = Samples[0][name];
            }
            setRows(tmpOrg);
        }
    }

    React.useEffect(() => {

        const GetSelectedMenuName = (e, v) => {
            if (selectedColumns && selectedColumns[e]) return selectedColumns[e].DisplayName;
            return v;
        }

        let items = [];
        Samples.forEach((elm, index) => {
            let tmp = {};
            for (let e in elm) {
                const tValue = index === 0 ? GetSelectedMenuName(e, elm[e]) : elm[e];
                tmp = { ...tmp, [e]: tValue };
            }
            items.push(tmp);
        });
        setRows(items);
    }, [selectedColumns]);


    return (
        <>
            <GridContainer>
                {rows && rows.map((x, index) => (
                    <CardItem keyIndex={index} key={index} width={290} row={x} {...props} selectedColumns={selectedColumns}
                        count={count} onMenuClosed={OnMenuClosed} />
                ))}
            </GridContainer>
        </>
    );
}

export default Component;