const serverApi = "https://configurator-bknd.code-wizard.in/";
const servletName = "Configurator_V8";

//const codeWizardServerApi = "https://codewizard-bknd.code-wizard.in/";
//const codeWizardServletName = "cwBackend132";


const GetAppInfoApi = async (id) => {

    if (!id) return null;

    const url = `${serverApi}${servletName}/AppConfigurations(${id})`;

    try {
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json"
            }
        });
        if (res.status === 200) {
            return await res.json();
        }
        return null;

    } catch (err) {
        return err;
    }
}

const PatchAppInfoApi = async (id, data) => {

    const url = `${serverApi}${servletName}/AppConfigurations(${id})`;

    try {
        const res = await fetch(url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json"
            }
        });
        if (res.status === 200) {
            return { status: res.ok };
        }

        return { status: false };

    } catch (err) {
        console.log(err);
        return { status: false };
    }
}

const GetDocumentsApi = async (id) => {

    if (!id) return null;

    const url = `${serverApi}${servletName}/Documents(${id})/$value`;

    try {
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json"
            }
        });
        if (res.status === 200) {
            return await res.text();
        }
        return null;

    } catch (err) {
        return err;
    }
}

const GetTemplatesApi = async (templateId, filters) => {

    if (!templateId) return null;

    const url = `${serverApi}${servletName}/UITemplates(${templateId})?$expand=AllScreens($filter=${filters})`;

    try {
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json"
            }
        });
        if (res.status === 200) {
            return await res.json();
        }
        return null;

    } catch (err) {
        return err;
    }
}

const SetTemplatesApi = async (data) => {

    const url = `${serverApi}${servletName}/FrontendApps`;

    try {
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json"
            }
        });
        if (res.status === 201) {
            const { AppId } = await res.json();
            return { status: res.ok, appId: AppId };
        }
        return { status: false };

    } catch (err) {
        console.log(err);
        return { status: false };
    }
}

const SetDocument = async (input, headers) => {
    return new Promise(async (resolve) => {
        let url = `${serverApi}${servletName}/Documents`;
        let method = "POST";
        const formData = new FormData();
        formData.append('file', input);
        try {
            const res = await fetch(url, {
                method, body: formData,
                headers: {
                    ...headers
                }
            });

            if (res.status === 201) {
                const json = await res.json();
                return resolve({ status: res.ok, id: json.DocId });
            } else {
                const json = await res.json();
                return resolve({ status: false, statusText: json.error.message });
            }

        } catch (error) {
            console.log(error);
            return resolve({ status: false, statusText: error.message });
        }
    });
}

export {
    GetAppInfoApi, PatchAppInfoApi, GetDocumentsApi,
    GetTemplatesApi, SetTemplatesApi, SetDocument
};
